import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const App = () => {
  const [costs, setCosts] = useState([
    { name: "Airfares", amount: 2749.4 + 4758.81 },
    { name: "Accommodation Chiang Mai", amount: 1142.82 },
    { name: "Accommodation Bangkok", amount: 762.98 },
  ]);

  const [people, setPeople] = useState([
    {
      id: 1,
      name: "Giantsbane",
      payments: [
        { date: "02/06/2024", amount: 1000 },
        { date: "03/06/2024", amount: 252 },
        { date: "06/06/2024", amount: 190.47 },
        { date: "29/06/2024", amount: 126.53 },
      ],
    },
    {
      id: 2,
      name: "Josh",
      payments: [
        { date: "02/06/2024", amount: 1000 },
        { date: "03/06/2024", amount: 252 },
        { date: "06/06/2024", amount: 190.47 },
        { date: "04/07/2024", amount: 126.53 },
      ],
    },
    {
      id: 3,
      name: "Boon",
      payments: [
        { date: "02/06/2024", amount: 1000 },
        { date: "02/06/2024", amount: 252 },
        { date: "06/06/2024", amount: 200 },
        { date: "14/07/2024", amount: 128 },
      ],
    },
    {
      id: 4,
      name: "Baughn",
      payments: [
        { date: "02/06/2024", amount: 333.33 },
        { date: "05/06/2024", amount: 167.67 },
        { date: "12/06/2024", amount: 150 },
        { date: "19/06/2024", amount: 150 },
        { date: "26/06/2024", amount: 100 },
        { date: "10/07/2024", amount: 75 },
        { date: "18/07/2024", amount: 100 },
        { date: "25/07/2024", amount: 75 },
      ],
    },
    {
      id: 5,
      name: "Terry",
      payments: [
        { date: "02/06/2024", amount: 1000 },
        { date: "05/06/2024", amount: 252 },
        { date: "16/06/2024", amount: 190.47 },
        { date: "15/07/2024", amount: 126.53 },
      ],
    },
    {
      id: 6,
      name: "Alicia",
      payments: [
        { date: "02/06/2024", amount: 1000 },
        { date: "05/06/2024", amount: 252 },
        { date: "16/06/2024", amount: 190.47 },
        { date: "15/07/2024", amount: 126.53 },
      ],
    },
  ]);

  useEffect(() => {
    const totalCost = costs.reduce((acc, cost) => acc + cost.amount, 0);
    const costPerPerson = totalCost / people.length;

    const updatedPeople = people.map((person) => {
      const totalPaid = person.payments.reduce(
        (acc, payment) => acc + payment.amount,
        0
      );
      return {
        ...person,
        paid: totalPaid,
        owes: costPerPerson - totalPaid,
      };
    });
    setPeople(updatedPeople);
  }, [costs, people.length]);

  const totalPaid = people.reduce((acc, person) => acc + person.paid, 0);
  const totalCost = costs.reduce((acc, cost) => acc + cost.amount, 0);

  return (
    <Container>
      <Typography
        variant="h4"
        component="h2"
        gutterBottom
        sx={{ marginTop: 4 }}
      >
        Total Trip Payment Progress
      </Typography>
      <Box position="relative" mt={4} mb={4}>
        <LinearProgress
          variant="determinate"
          value={(totalPaid / totalCost) * 100}
          sx={{ height: 50, borderRadius: 5 }}
        />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body1">
          Total Paid: ${totalPaid.toFixed(2)}
        </Typography>
        <Typography variant="body1">
          Total Cost: ${totalCost.toFixed(2)}
        </Typography>
      </Box>

      <Typography variant="h4" component="h2" gutterBottom>
        Cost Breakdown
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Cost Name</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {costs.map((cost, index) => (
              <TableRow key={index}>
                <TableCell>{cost.name}</TableCell>
                <TableCell>${cost.amount.toFixed(2)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography
        variant="h4"
        component="h2"
        gutterBottom
        sx={{ marginTop: 4 }}
      >
        Payment Details
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Amount Paid</TableCell>
              <TableCell>Amount Owed</TableCell>
              <TableCell>Progress</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      {people.map((person) => (
        <Accordion key={person.id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography>{person.name}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>${(person.paid || 0).toFixed(2)}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>${(person.owes || 0).toFixed(2)}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Box display="flex" alignItems="center">
                  <Box width="100%" mr={1}>
                    <LinearProgress
                      variant="determinate"
                      value={
                        (person.paid / (person.paid + person.owes)) * 100 || 0
                      }
                    />
                  </Box>
                  <Box minWidth={35}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                    >{`${Math.round(
                      (person.paid / (person.paid + person.owes)) * 100 || 0
                    )}%`}</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {person.payments.map((payment, index) => (
                    <TableRow key={index}>
                      <TableCell>{payment.date}</TableCell>
                      <TableCell>${payment.amount.toFixed(2)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
};

export default App;
